export const dropperTravel = {
  id: 'dropperTravel',
  key: 'dropper_travel',
  label: 'Dropper travel',
  mandatory: false,
  collapsible: false,
  inputType: 'TEXT',
  type: 'SelectMultipleFilter',
  textInputType: 'number',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 50,
  maxValue: 250,
};

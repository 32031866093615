import { frameMaterial, frameSize, rearTravel, wheelSize } from '../specifications';

import FrameCategoryLogo from '../../assets/part_categories/frame.png';
import { discipline } from '../specifications/discipline';

export const frame = {
  key: 'frame',
  label: 'Frame',
  logo: FrameCategoryLogo,
  subcategories: [
    {
      key: 'frame_full_suspension',
      value: 'frame_full_suspension',
      label: 'Full suspension',
      specifications: [frameSize, discipline, frameMaterial({}), wheelSize({}), rearTravel],
    },
    {
      key: 'frame_hard_tail_mtb',
      value: 'frame_hard_tail_mtb',
      label: 'Hardtail MTB',
      specifications: [frameSize, discipline, frameMaterial({}), wheelSize({})],
    },
    {
      key: 'frame_road',
      value: 'frame_road',
      label: 'Road',
      specifications: [frameSize, frameMaterial({ collapsible: false }), wheelSize({ collapsible: false })],
    },
    {
      key: 'frame_gravel',
      value: 'frame_gravel',
      label: 'Gravel',
      specifications: [frameSize, frameMaterial({ collapsible: false }), wheelSize({ collapsible: false })],
    },
    {
      key: 'frame_full_suspension_e_bike',
      value: 'frame_full_suspension_e_bike',
      label: 'Full suspension E-Bike',
      specifications: [frameSize, frameMaterial({}), wheelSize({ collapsible: false }), rearTravel],
    },
    {
      key: 'frame_hard_tail_e_bike',
      value: 'frame_hard_tail_e_bike',
      label: 'Hardtail E-Bike',
      specifications: [frameSize, frameMaterial({}), wheelSize({ collapsible: false })],
    },
    {
      key: 'frame_city',
      value: 'frame_city',
      label: 'City',
      specifications: [frameSize, frameMaterial({ collapsible: false }), wheelSize({ collapsible: false })],
    },
    {
      key: 'frame_dirt_n_street',
      value: 'frame_dirt_n_street',
      label: 'Dirt & Street',
      specifications: [frameSize, frameMaterial({}), wheelSize({ collapsible: false })],
    },
  ],
};

import TyresTubesCategoryLogo from '../assets/part_categories/tyres_tubes.png';

import { brakes, cockpit, drivetrain, frame, seat, suspension, wheels } from './partCategories';
import { accessories } from './snow-categories/accessories';
import { skis } from './snow-categories/skis';
import { snowboard } from './snow-categories/snowboards';
import { wheelSize } from './specifications';

export const partsConfig = [
  {
    id: 'parts',
    label: 'Part category',
    type: 'SelectMultipleFilter',
    isOpen: true,
    queryParamNames: ['pub_parts_category'],
    config: {
      schemaType: 'enum',
      options: [
        frame,
        suspension,
        drivetrain,
        brakes,
        wheels,
        {
          key: 'tyres_tubes',
          label: 'Tyres & Tubes',
          logo: TyresTubesCategoryLogo,
          specifications: [wheelSize({ collapsible: false, isFilterable: true })],
        },
        cockpit,
        seat,
      ],
    },
  },
  {
    id: 'winter',
    label: 'Winter category',
    type: 'SelectMultipleFilter',
    isOpen: true,
    queryParamNames: ['pub_snow_category'],
    config: {
      schemaType: 'enum',
      options: [
       skis,
       snowboard,
       accessories
      ],
    },
  },
  {
    id: 'condition',
    label: 'Condition',
    translationGroup: 'condition',
    isOpen: false,
    type: 'SelectMultipleFilter',
    group: 'primary',
    sortingOrder: 5,
    queryParamNames: ['pub_condition'],
    config: {
      schemaType: 'enum',

      options: [
        {
          key: 'used_part',
          value: 0,
          label: 'Used',
          labelInfo: '✔ A used item with imperfections',
        },
        {
          key: 'good_part',
          value: 50,
          label: 'Good',
          labelInfo: '✔ A lightly used item, but still in great shape',
        },
        {
          key: 'new_part',
          value: 100,
          label: 'New',
          labelInfo: '✔ Brand new, unused item',
        },
      ],
    },
  },
];

export const diskDiameter = ({ type = '' }) => ({
  id: type != '' ? `${type}DiskDiameter` : 'disk_diameter',
  key: type != '' ? `${type}_disk_diameter` : 'disk_diameter',
  label: 'Disk diameter',
  mandatory: false,
  collapsible: false,
  inputType: 'TEXT',
  type: 'SelectMultipleFilter',
  textInputType: 'number',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 0,
  maxValue: 250,
});

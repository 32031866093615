import AccessoriesCategoryLogo from '../../assets/snow_categories/accessories.png';
import { color, gender, gogglesFrameSize, helmetSize } from "./specifications";

export const accessories = {
  key: 'accessories',
  label: 'Accessories',
  logo: AccessoriesCategoryLogo,
  subcategories: [
    {
      key: 'helmets',
      value: 'helmets',
      label: 'Helmets',
      specifications: [helmetSize, gender, color],
    },
    {
      key: 'goggles',
      value: 'goggles',
      label: 'Goggles',
      specifications: [gogglesFrameSize, gender, color],
    },
  ],
}
import DrivetrainCategoryLogo from '../../assets/part_categories/drivetrain.png';

import {
  activation,
  axleDimension,
  bottomBracketMounting,
  bottomBracketWidth,
  chainringMounting,
  chainringTeeth,
  crankArmLength,
  frameMaterial,
  gears,
  pedals,
  placement,
} from '../specifications';

export const drivetrain = {
  key: 'drivetrain',
  label: 'Drivetrain',
  logo: DrivetrainCategoryLogo,
  subcategories: [
    {
      key: 'drivetrain_crank',
      value: 'drivetrain_crank',
      label: 'Crank',
      specifications: [axleDimension, frameMaterial({ collapsible: false, isFilterable: true }), crankArmLength],
    },
    {
      key: 'drivetrain_chain',
      value: 'drivetrain_chain',
      label: 'Chain',
      specifications: [gears({ mandatory: false })],
    },
    {
      key: 'drivetrain_cassette',
      value: 'drivetrain_cassette',
      label: 'Cassette',
      specifications: [gears({ mandatory: false })],
    },
    {
      key: 'drivetrain_shifter',
      value: 'drivetrain_shifter',
      label: 'Shifter',
      specifications: [placement, activation],
    },
    {
      key: 'drivetrain_derailleur',
      value: 'drivetrain_derailleur',
      label: 'Derailleur',
      specifications: [placement, activation],
    },
    {
      key: 'drivetrain_chainring',
      value: 'drivetrain_chainring',
      label: 'Chainring',
      specifications: [chainringMounting, chainringTeeth],
    },
    {
      key: 'drivetrain_bottom_bracket',
      value: 'drivetrain_bottom_bracket',
      label: 'Bottom bracket',
      specifications: [bottomBracketMounting, axleDimension, bottomBracketWidth],
    },
    {
      key: 'drivetrain_chain_guides',
      value: 'drivetrain_chain_guides',
      label: 'Chain guides',
      specifications: [],
    },
    {
      key: 'drivetrain_pedals',
      value: 'drivetrain_pedals',
      label: 'Pedals',
      specifications: [pedals],
    },
  ],
};

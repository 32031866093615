export const steererType = {
  id: 'steererType',
  key: 'steerer_type',
  label: 'Steerer type',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'steerer_type',
  queryParamNames: ['pub_steererType'],
  inputType: 'DROPDOWN',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: '1_5', value: '1_5', label: '1.5”' },
      { key: '1_8', value: '1_8', label: '1-1/8”' },
      { key: 'threaded', value: 'threaded', label: 'Threaded' },
      { key: 'tapered', value: 'tapered', label: 'Tapered' },
    ],
  },
};

export const hubStandard = ({ type = '', isFilterable = false }) => ({
  id: type != '' ? `${type}HubStandard` : 'hubStandard',
  key: type != '' ? `${type}_hub_standard` : 'hub_standard',
  label: 'Hub standard',
  mandatory: false,
  isFilterable,
  isOpen: false,
  inputType: 'DROPDOWN',
  translationGroup: 'hub_standard',
  queryParamNames: [type != '' ? `pub_${type}HubStandard` : 'pub_hubStandard'],
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: '15_110', value: '15_110', label: '15 x 110mm (Boost)' },
      { key: '20_110', value: '20_110', label: '20 x 110mm (Boost)' },
      { key: '9_100', value: '9_100', label: '9 x 100mm' },
      { key: '15_100', value: '15_100', label: '15 x 100mm' },
      { key: '20_100', value: '20_100', label: '20 x 100mm' },
      { key: '9_135', value: '9_135', label: '9 x 135mm' },
      { key: '10_135', value: '10_135', label: '10 x 135mm' },
      { key: '15_142', value: '15_142', label: '15 x 142mm' },
      { key: '15_150', value: '15_150', label: '15 x 150mm' },
      { key: '10_170', value: '10_170', label: '10 x 170mm' },
      { key: '12_190', value: '12_190', label: '12 x 190mm' },
      { key: '12_197', value: '12_197', label: '12 x 197mm' },
    ],
  },
});

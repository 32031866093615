export const withBindings = {
  id: 'withBindings',
  key: 'with_bindings',
  label: 'With bindings',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_withBindings'],
  translationGroup: 'with_bindings',
  translatableLabels: true,
  config: {
    options: [
      { key: "yes", value: "yes", label: "Yes" },
      { key: "no", value: "no", label: "No" },
    ]
  },
}

export const polesSize = {
  id: 'polesSize',
  key: 'poles_size',
  label: 'Poles size',
  mandatory: true,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_polesSize'],
  translationGroup: 'poles_size',
  translatableLabels: true,
  config: {
    options: [
      { key: "adjustable", value: "adjustable", label: "Adjustable" },
      { key: "34", value: "34", label: "34in (85cm)" },
      { key: "36", value: "36", label: "36in (90cm)" },
      { key: "38", value: "38", label: "38in (95cm)" },
      { key: "40", value: "40", label: "40in (100cm)" },
      { key: "42", value: "42", label: "42in (105cm)" },
      { key: "44", value: "44", label: "44in (110cm)" },
      { key: "46", value: "46", label: "46in (115cm)" },
      { key: "48", value: "48", label: "48in (120cm)" },
      { key: "50", value: "50", label: "50in (125cm)" },
      { key: "52", value: "52", label: "52in (130cm)" },
      { key: "54", value: "54", label: "54in (135cm)" },
      { key: "56", value: "56", label: "56in (140cm)" },
      { key: "more_than_56", value: "more_than_56", label: "More than 56in (140cm)" },
      { key: "other", value: "other", label: "Other / Unknown" }
    ]
  },
}

export const bootsSize = {
  id: 'bootsSize',
  key: 'boots_size',
  label: 'Boots size',
  mandatory: true,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_bootsSize'],
  translationGroup: 'boots_size',
  skipItemPageTranslations: true,
  config: {
    options: [
      {key: '25', value: '25', label: '25'},
      {key: '26', value: '26', label: '26'},
      {key: '26_1/3', value: '26_1/3', label: '26 1/3'},
      {key: '26_2/3', value: '26_2/3', label: '26 2/3'},
      {key: '26_5', value: '26_5', label: '26,5'},
      {key: '27_5', value: '27_5', label: '27,5'},
      {key: '27_2/3', value: '27_2/3', label: '27 2/3'},
      {key: '28', value: '28', label: '28'},
      {key: '29', value: '29', label: '29'},
      {key: '29_5', value: '29_5', label: '29,5'},
      {key: '30_5', value: '30_5', label: '30,5'},
      {key: '30_2/3', value: '30_2/3', label: '30 2/3'},
      {key: '31', value: '31', label: '31'},
      {key: '31_5', value: '31_5', label: '31,5'},
      {key: '32', value: '32', label: '32'},
      {key: '32_1/3', value: '32_1/3', label: '32 1/3'},
      {key: '32_5', value: '32_5', label: '32,5'},
      {key: '33', value: '33', label: '33'},
      {key: '33_5', value: '33_5', label: '33,5'},
      {key: '33_2/3', value: '33_2/3', label: '33 2/3'},
      {key: '34', value: '34', label: '34'},
      {key: '34_5', value: '34_5', label: '34,5'},
      {key: '34_2/3', value: '34_2/3', label: '34 2/3'},
      {key: '35', value: '35', label: '35'},
      {key: '35_1/3', value: '35_1/3', label: '35 1/3'},
      {key: '35_5', value: '35_5', label: '35,5'},
      {key: '36', value: '36', label: '36'},
      {key: '36_5', value: '36_5', label: '36,5'},
      {key: '36_2/3', value: '36_2/3', label: '36 2/3'},
      {key: '37', value: '37', label: '37'},
      {key: '37_1/3', value: '37_1/3', label: '37 1/3'},
      {key: '37_5', value: '37_5', label: '37,5'},
      {key: '38', value: '38', label: '38'},
      {key: '38_1/3', value: '38_1/3', label: '38 1/3'},
      {key: '38_5', value: '38_5', label: '38,5'},
      {key: '38_2/3', value: '38_2/3', label: '38 2/3'},
      {key: '39', value: '39', label: '39'},
      {key: '39_1/3', value: '39_1/3', label: '39 1/3'},
      {key: '39_5', value: '39_5', label: '39,5'},
      {key: '39_2/3', value: '39_2/3', label: '39 2/3'},
      {key: '40', value: '40', label: '40'},
      {key: '40_1/3', value: '40_1/3', label: '40 1/3'},
      {key: '40_5', value: '40_5', label: '40,5'},
      {key: '40_2/3', value: '40_2/3', label: '40 2/3'},
      {key: '41', value: '41', label: '41'},
      {key: '41_1/3', value: '41_1/3', label: '41 1/3'},
      {key: '41_5', value: '41_5', label: '41,5'},
      {key: '42', value: '42', label: '42'},
      {key: '42_5', value: '42_5', label: '42,5'},
      {key: '42_2/3', value: '42_2/3', label: '42 2/3'},
      {key: '43', value: '43', label: '43'},
      {key: '43_1/3', value: '43_1/3', label: '43 1/3'},
      {key: '43_5', value: '43_5', label: '43,5'},
      {key: '44', value: '44', label: '44'},
      {key: '44_1/3', value: '44_1/3', label: '44 1/3'},
      {key: '44_5', value: '44_5', label: '44,5'},
      {key: '44_2/3', value: '44_2/3', label: '44 2/3'},
      {key: '45', value: '45', label: '45'},
      {key: '45_1/3', value: '45_1/3', label: '45 1/3'},
      {key: '45_5', value: '45_5', label: '45,5'},
      {key: '45_2/3', value: '45_2/3', label: '45 2/3'},
      {key: '46', value: '46', label: '46'},
      {key: '46_1/3', value: '46_1/3', label: '46 1/3'},
      {key: '46_5', value: '46_5', label: '46,5'},
      {key: '46_2/3', value: '46_2/3', label: '46 2/3'},
      {key: '47', value: '47', label: '47'},
      {key: '47_1/3', value: '47_1/3', label: '47 1/3'},
      {key: '47_5', value: '47_5', label: '47,5'},
      {key: '48', value: '48', label: '48'},
      {key: '48_5', value: '48_5', label: '48,5'},
      {key: '48_2/3', value: '48_2/3', label: '48 2/3'},
      {key: '49', value: '49', label: '49'},
      {key: '49_1/3', value: '49_1/3', label: '49 1/3'},
      {key: '49_5', value: '49_5', label: '49,5'}
    ]
  },
}

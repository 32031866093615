import BrakesCategoryLogo from '../../assets/part_categories/brakes.png';

import { brakeType, brakesActivation, rotorMounting, rotorPlacement } from '../specifications';

export const brakes = {
  key: 'brakes',
  label: 'Brakes',
  logo: BrakesCategoryLogo,
  subcategories: [
    {
      key: 'brakes_disk',
      value: 'brakes_disk',
      label: 'Disk brakes',
      specifications: [brakeType, brakesActivation],
    },
    {
      key: 'brakes_rim',
      value: 'brakes_rim',
      label: 'Rim brakes',
      specifications: [brakeType, brakesActivation],
    },
    {
      key: 'brakes_rotor',
      value: 'brakes_rotor',
      label: 'Brake rotor',
      specifications: [rotorMounting, rotorPlacement],
    },
    {
      key: 'brakes_other',
      value: 'brakes_other',
      label: 'Other parts',
      specifications: [],
    },
  ],
};

export const skiLenght = {
  id: 'skiLenght',
  key: 'ski_length',
  label: 'Ski length',
  mandatory: true,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_skiLenght'],
  translationGroup: 'ski_length',
  skipItemPageTranslations: true,
  config: {
    options: [
      { key: "60", value: "60", label: "60" },
      { key: "61", value: "61", label: "61" },
      { key: "62", value: "62", label: "62" },
      { key: "63", value: "63", label: "63" },
      { key: "64", value: "64", label: "64" },
      { key: "65", value: "65", label: "65" },
      { key: "66", value: "66", label: "66" },
      { key: "67", value: "67", label: "67" },
      { key: "68", value: "68", label: "68" },
      { key: "69", value: "69", label: "69" },
      { key: "70", value: "70", label: "70" },
      { key: "71", value: "71", label: "71" },
      { key: "72", value: "72", label: "72" },
      { key: "73", value: "73", label: "73" },
      { key: "74", value: "74", label: "74" },
      { key: "75", value: "75", label: "75" },
      { key: "76", value: "76", label: "76" },
      { key: "77", value: "77", label: "77" },
      { key: "78", value: "78", label: "78" },
      { key: "79", value: "79", label: "79" },
      { key: "80", value: "80", label: "80" },
      { key: "81", value: "81", label: "81" },
      { key: "82", value: "82", label: "82" },
      { key: "83", value: "83", label: "83" },
      { key: "84", value: "84", label: "84" },
      { key: "85", value: "85", label: "85" },
      { key: "86", value: "86", label: "86" },
      { key: "87", value: "87", label: "87" },
      { key: "88", value: "88", label: "88" },
      { key: "89", value: "89", label: "89" },
      { key: "90", value: "90", label: "90" },
      { key: "91", value: "91", label: "91" },
      { key: "92", value: "92", label: "92" },
      { key: "93", value: "93", label: "93" },
      { key: "94", value: "94", label: "94" },
      { key: "95", value: "95", label: "95" },
      { key: "96", value: "96", label: "96" },
      { key: "97", value: "97", label: "97" },
      { key: "98", value: "98", label: "98" },
      { key: "99", value: "99", label: "99" },
      { key: "100", value: "100", label: "100" },
      { key: "101", value: "101", label: "101" },
      { key: "102", value: "102", label: "102" },
      { key: "103", value: "103", label: "103" },
      { key: "104", value: "104", label: "104" },
      { key: "105", value: "105", label: "105" },
      { key: "106", value: "106", label: "106" },
      { key: "107", value: "107", label: "107" },
      { key: "108", value: "108", label: "108" },
      { key: "109", value: "109", label: "109" },
      { key: "110", value: "110", label: "110" },
      { key: "111", value: "111", label: "111" },
      { key: "112", value: "112", label: "112" },
      { key: "113", value: "113", label: "113" },
      { key: "114", value: "114", label: "114" },
      { key: "115", value: "115", label: "115" },
      { key: "116", value: "116", label: "116" },
      { key: "117", value: "117", label: "117" },
      { key: "118", value: "118", label: "118" },
      { key: "119", value: "119", label: "119" },
      { key: "120", value: "120", label: "120" },
      { key: "121", value: "121", label: "121" },
      { key: "122", value: "122", label: "122" },
      { key: "123", value: "123", label: "123" },
      { key: "124", value: "124", label: "124" },
      { key: "125", value: "125", label: "125" },
      { key: "126", value: "126", label: "126" },
      { key: "127", value: "127", label: "127" },
      { key: "128", value: "128", label: "128" },
      { key: "129", value: "129", label: "129" },
      { key: "130", value: "130", label: "130" },
      { key: "131", value: "131", label: "131" },
      { key: "132", value: "132", label: "132" },
      { key: "133", value: "133", label: "133" },
      { key: "134", value: "134", label: "134" },
      { key: "135", value: "135", label: "135" },
      { key: "136", value: "136", label: "136" },
      { key: "137", value: "137", label: "137" },
      { key: "138", value: "138", label: "138" },
      { key: "139", value: "139", label: "139" },
      { key: "140", value: "140", label: "140" },
      { key: "141", value: "141", label: "141" },
      { key: "142", value: "142", label: "142" },
      { key: "143", value: "143", label: "143" },
      { key: "144", value: "144", label: "144" },
      { key: "145", value: "145", label: "145" },
      { key: "146", value: "146", label: "146" },
      { key: "147", value: "147", label: "147" },
      { key: "148", value: "148", label: "148" },
      { key: "149", value: "149", label: "149" },
      { key: "150", value: "150", label: "150" },
      { key: "151", value: "151", label: "151" },
      { key: "152", value: "152", label: "152" },
      { key: "153", value: "153", label: "153" },
      { key: "154", value: "154", label: "154" },
      { key: "155", value: "155", label: "155" },
      { key: "156", value: "156", label: "156" },
      { key: "157", value: "157", label: "157" },
      { key: "158", value: "158", label: "158" },
      { key: "159", value: "159", label: "159" },
      { key: "160", value: "160", label: "160" },
      { key: "161", value: "161", label: "161" },
      { key: "162", value: "162", label: "162" },
      { key: "163", value: "163", label: "163" },
      { key: "164", value: "164", label: "164" },
      { key: "165", value: "165", label: "165" },
      { key: "166", value: "166", label: "166" },
      { key: "167", value: "167", label: "167" },
      { key: "168", value: "168", label: "168" },
      { key: "169", value: "169", label: "169" },
      { key: "170", value: "170", label: "170" },
      { key: "171", value: "171", label: "171" },
      { key: "172", value: "172", label: "172" },
      { key: "173", value: "173", label: "173" },
      { key: "174", value: "174", label: "174" },
      { key: "175", value: "175", label: "175" },
      { key: "176", value: "176", label: "176" },
      { key: "177", value: "177", label: "177" },
      { key: "178", value: "178", label: "178" },
      { key: "179", value: "179", label: "179" },
      { key: "180", value: "180", label: "180" },
      { key: "181", value: "181", label: "181" },
      { key: "182", value: "182", label: "182" },
      { key: "183", value: "183", label: "183" },
      { key: "184", value: "184", label: "184" },
      { key: "185", value: "185", label: "185" },
      { key: "186", value: "186", label: "186" },
      { key: "187", value: "187", label: "187" },
      { key: "188", value: "188", label: "188" },
      { key: "189", value: "189", label: "189" },
      { key: "190", value: "190", label: "190" },
      { key: "191", value: "191", label: "191" },
      { key: "192", value: "192", label: "192" },
      { key: "193", value: "193", label: "193" },
      { key: "194", value: "194", label: "194" },
      { key: "195", value: "195", label: "195" },
      { key: "196", value: "196", label: "196" },
      { key: "197", value: "197", label: "197" },
      { key: "198", value: "198", label: "198" },
      { key: "199", value: "199", label: "199" },
      { key: "200", value: "200", label: "200" },
      { key: "201", value: "201", label: "201" },
      { key: "202", value: "202", label: "202" },
      { key: "203", value: "203", label: "203" },
      { key: "204", value: "204", label: "204" },
      { key: "205", value: "205", label: "205" },
      { key: "206", value: "206", label: "206" },
      { key: "207", value: "207", label: "207" },
      { key: "208", value: "208", label: "208" },
      { key: "209", value: "209", label: "209" },
      { key: "210", value: "210", label: "210" },
      { key: "211", value: "211", label: "211" },
      { key: "212", value: "212", label: "212" },
      { key: "213", value: "213", label: "213" },
      { key: "214", value: "214", label: "214" },
      { key: "215", value: "215", label: "215" },
      { key: "216", value: "216", label: "216" },
      { key: "217", value: "217", label: "217" },
      { key: "218", value: "218", label: "218" },
      { key: "219", value: "219", label: "219" },
      { key: "220", value: "220", label: "220" },
      { key: "221", value: "221", label: "221" },
      { key: "222", value: "222", label: "222" },
      { key: "223", value: "223", label: "223" },
      { key: "224", value: "224", label: "224" },
      { key: "225", value: "225", label: "225" },
      { key: "226", value: "226", label: "226" },
      { key: "227", value: "227", label: "227" },
      { key: "228", value: "228", label: "228" },
      { key: "229", value: "229", label: "229" }
    ]    
  },
}

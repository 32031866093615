export const helmetSize = {
  id: 'helmetSize',
  key: 'helmet_size',
  label: 'Snowboard size',
  mandatory: true,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_helmetSize'],
  translationGroup: 'helmet_size',
  skipItemPageTranslations: true,
  config: {
    options: [
      { key: "xs", value: "xs", label: "XS" },
      { key: "small", value: "small", label: "S" },
      { key: "medium", value: "medium", label: "M" },
      { key: "medium_large", value: "medium_large", label: "M/L" },
      { key: "large", value: "large", label: "L" },
      { key: "xl", value: "xl", label: "XL" },
      { key: "xxl", value: "xxl", label: "XXL" },
      { key: "other", value: "other", label: "Other" }
    ]
  },
}

export const chainringMounting = {
  id: 'chainringMounting',
  key: 'chainring_mounting',
  label: 'Chainring mounting',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'chainring_mounting',
  inputType: 'DROPDOWN',
  queryParamNames: ['pub_chainringMounting'],
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: '3_arm', value: '3_arm', label: '3-arm' },
      { key: '4_arm', value: '4_arm', label: '4-arm' },
      { key: '5_arm', value: '5_arm', label: '5-arm' },
    ],
  },
};

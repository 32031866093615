export const suspensionType = ({ collapsible = true, isFilterable = true }) => ({
  id: 'suspensionType',
  key: 'suspension_type',
  label: 'Suspension type',
  mandatory: false,
  isFilterable,
  collapsible,
  queryParamNames: ['pub_suspensionType'],
  inputType: 'CHECKBOX',
  type: 'SelectMultipleFilter',
  translationGroup: 'suspension_type',
  isOpen: false,
  config: {
    schemaType: 'enum',
    options: [
      { key: 'air', value: 'air', label: 'Air' },
      { key: 'coil', value: 'coil', label: 'Coil' },
    ],
  },
});

export const handlebarTypes = {
  id: 'handlebarType',
  key: 'handlebar_type',
  label: 'Handlebar type',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'handlebar_type',
  inputType: 'DROPDOWN',
  queryParamNames: ['pub_handlebarType'],
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'mtb', value: 'mtb', label: 'Mtb' },
      { key: 'dropbar', value: 'dropbar', label: 'Dropbar' },
      { key: 'bmx', value: 'bmx', label: 'Bmx' },
      { key: 'city_trekking', value: 'city_trekking', label: 'City/Trekking' },
    ],
  },
};

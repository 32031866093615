export const activation = {
  id: 'activation',
  key: 'activation',
  label: 'Activation',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'activation',
  inputType: 'CHECKBOX',
  queryParamNames: ['pub_activation'],
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'mechanical', value: 'mechanical', label: 'Mechanical' },
      { key: 'electric', value: 'electric', label: 'Electric' },
    ],
  },
};

export const gogglesFrameSize = {
  id: 'gogglesFrameSize',
  key: 'goggles_frame_size',
  label: 'Snowboard size',
  mandatory: true,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_gogglesFrameSize'],
  translationGroup: 'goggles_frame_size',
  translatableLabels: true,
  config: {
    options: [
      { key: 'small', value: 'small', label: 'Small' },
      { key: 'medium', value: 'medium', label: 'Medium' },
      { key: 'large', value: 'large', label: 'Large' },
    ]
  },
}

export const snowboardSize = {
  id: 'snowboardSize',
  key: 'snowboard_size',
  label: 'Snowboard size',
  mandatory: true,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_snowboardSize'],
  translationGroup: 'snowboard_size',
  skipItemPageTranslations: true,
  config: {
    options: [
      { key: "less_than_106", value: "less_than_106", label: "Less than 106 cm" },
      { key: "106_110", value: "106_110", label: "106 - 110 cm" },
      { key: "111_115", value: "111_115", label: "111 - 115 cm" },
      { key: "116_120", value: "116_120", label: "116 - 120 cm" },
      { key: "121_125", value: "121_125", label: "121 - 125 cm" },
      { key: "126_130", value: "126_130", label: "126 - 130 cm" },
      { key: "131_135", value: "131_135", label: "131 - 135 cm" },
      { key: "136_140", value: "136_140", label: "136 - 140 cm" },
      { key: "141_145", value: "141_145", label: "141 - 145 cm" },
      { key: "146_150", value: "146_150", label: "146 - 150 cm" },
      { key: "151_155", value: "151_155", label: "151 - 155 cm" },
      { key: "156_160", value: "156_160", label: "156 - 160 cm" },
      { key: "161_165", value: "161_165", label: "161 - 165 cm" },
      { key: "166_170", value: "166_170", label: "166 - 170 cm" },
      { key: "171_175", value: "171_175", label: "171 - 175 cm" },
      { key: "176_180", value: "176_180", label: "176 - 180 cm" },
      { key: "more_than_180", value: "more_than_180", label: "More than 180 cm" },
      { key: "other", value: "other", label: "Other" }
    ]
  },
}

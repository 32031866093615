export const bottomBracketMounting = {
  id: 'bottomBracketMounting',
  key: 'bottom_bracket_mounting',
  label: 'Bottom bracket mounting',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'bottom_bracket_mounting',
  inputType: 'DROPDOWN',
  queryParamNames: ['pub_bottomBracketMounting'],
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'bsa', value: 'bsa', label: 'BSA' },
      { key: 'ita', value: 'ita', label: 'ITA' },
      { key: 'pressfit', value: 'pressfit', label: 'Pressfit' },
    ],
  },
};

export const frameMaterial = ({ mandatory = false, collapsible = true, isFilterable = false }) => ({
  id: 'frameMaterial',
  key: 'frame_material',
  label: 'Frame material',
  mandatory,
  collapsible,
  isFilterable,
  isOpen: false,
  inputType: 'CHECKBOX',
  queryParamNames: ['pub_frameMaterial'],
  type: 'SelectMultipleFilter',
  translationGroup: 'frame_material',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'aluminum', value: 'aluminum', label: 'Aluminium' },
      { key: 'carbon', value: 'carbon', label: 'Carbon' },
      { key: 'steel', value: 'steel', label: 'Steel' },
      { key: 'other', value: 'other', label: 'Other' },
    ],
  },
});

export const snowboardBindingsSize = {
  id: 'snowboardBindingsSize',
  key: 'snowboard_bindings_size',
  label: 'Snowboard binding size',
  mandatory: true,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_snowboardBindingsSize'],
  translationGroup: 'snowboard_bindings_size',
  translatableLabels: true,
  config: {
    options: [
      { key: 'xs', value: 'xs', label: 'Extra small' },
      { key: 's', value: 's', label: 'Small' },
      { key: 'm', value: 'm', label: 'Medium' },
      { key: 'l', value: 'l', label: 'Large' },
      { key: 'xl', value: 'xl', label: 'Extra large' },
      { key: 'one_size', value: 'one_size', label: 'One size fits all' },
      { key: 'other', value: 'other', label: 'Other' },
    ],
  },
}

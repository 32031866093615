export const snowboardType = {
  id: 'snowboardType',
  key: 'snowboard_type',
  label: 'Snowboard size',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_snowboardType'],
  translationGroup: 'snowboard_type',
  translatableLabels: true,
  config: {
    options: [
      { key: "all_mountain", value: "all_mountain", label: "All Mountain" },
      { key: "freeride", value: "freeride", label: "Freeride" },
      { key: "freestyle", value: "freestyle", label: "Freestyle" },
      { key: "park_pipe", value: "park_pipe", label: "Park & Pipe" },
      { key: "powder", value: "powder", label: "Powder" },
      { key: "splitboards", value: "splitboards", label: "Splitboards" },
      { key: "wide", value: "wide", label: "Wide" }
    ]
  },
}

export const color = {
  id: 'color',
  key: 'color',
  label: 'Snowboard size',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_color'],
  translationGroup: 'color',
  translatableLabels: true,
  config: {
    options: [
      { key: 'pink', value: 'pink', label: 'Pink' },
      { key: 'green', value: 'green', label: 'Green' },
      { key: 'orange', value: 'orange', label: 'Orange' },
      { key: 'blue', value: 'blue', label: 'Blue' },
      { key: 'red', value: 'red', label: 'Red' },
      { key: 'white', value: 'white', label: 'White' },
      { key: 'yellow', value: 'yellow', label: 'Yellow' },
      { key: 'black', value: 'black', label: 'Black' },
      { key: 'grey', value: 'grey', label: 'Grey/metal' },
      { key: 'other', value: 'other', label: 'Other' },
    ]
  },
}

export const snowboardFlexRate = {
  id: 'snowboardFlexRate',
  key: 'snowboard_flex_rate',
  label: 'Snowboard size',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'SelectMultipleFilter',
  queryParamNames: ['pub_snowboardFlexRate'],
  translationGroup: 'snowboard_flex_rate',
  translatableLabels: true,
  config: {
    options: [
      { key: "soft", value: "soft", label: "Soft" },
      { key: "medium", value: "medium", label: "Medium" },
      { key: "stiff", value: "stiff", label: "Stiff" },
      { key: "very_stiff", value: "very_stiff", label: "Very Stiff" }
    ],
  },
}

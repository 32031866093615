export const skiBindingsSize = {
  id: 'skiBindingsSize',
  key: 'ski_bindings_size',
  label: 'Ski bindings size',
  mandatory: true,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_skiBindingsSize'],
  translationGroup: 'ski_bindings_size',
  skipItemPageTranslations: true,
  config: {
    options: [
      { key: '85', value: '85', label: '85mm' },
      { key: '100', value: '100', label: '100mm' },
      { key: '110', value: '110', label: '110mm' },
      { key: '115', value: '115', label: '115mm' },
      { key: '120', value: '120', label: '120mm' },
      { key: '130', value: '130', label: '130mm' },
    ],
  },
}

export const rearTravel = {
  id: 'rearTravel',
  key: 'rear_travel',
  label: 'Rear travel',
  mandatory: false,
  collapsible: true,
  isFilterable: true,
  isOpen: false,
  inputType: 'TEXT',
  type: 'DropDownFilter',
  queryParamNames: ['pub_rearTravel'],
  translationGroup: 'rear_travel',
  textInputType: 'number',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 60,
  maxValue: 230,
  config: {
    schemaType: 'enum',
    labelKey: 'rearTravel',

    options: [
      { key: '60', value: '60', label: '60' },
      { key: '70', value: '70', label: '70' },
      { key: '80', value: '80', label: '80' },
      { key: '90', value: '90', label: '90' },
      { key: '100', value: '100', label: '100' },
      { key: '110', value: '110', label: '110' },
      { key: '120', value: '120', label: '120' },
      { key: '130', value: '130', label: '130' },
      { key: '140', value: '140', label: '140' },
      { key: '150', value: '150', label: '150' },
      { key: '160', value: '160', label: '160' },
      { key: '170', value: '170', label: '170' },
      { key: '180', value: '180', label: '180' },
      { key: '190', value: '190', label: '190' },
      { key: '200', value: '200', label: '200' },
      { key: '210', value: '210', label: '210' },
      { key: '220', value: '220', label: '220' },
      { key: '230', value: '230', label: '230' },
    ],
  },
};

import SuspensionCategoryLogo from '../../assets/part_categories/suspension.png';
import {
  frameMaterial,
  frontTravel,
  hubStandard,
  suspensionLength,
  suspensionMounting,
  suspensionStroke,
  suspensionType,
  wheelSize,
} from '../specifications';

export const suspension = {
  key: 'suspension',
  label: 'Suspension',
  logo: SuspensionCategoryLogo,
  subcategories: [
    {
      key: 'suspension_rigid_fork',
      value: 'suspension_rigid_fork',
      label: 'Rigid fork',
      specifications: [
        wheelSize({ collapsible: false, isFilterable: true }),
        hubStandard({ isFilterable: true }),
        frameMaterial({ collapsible: false, isFilterable: true }),
      ],
    },
    {
      key: 'suspension_suspension_fork',
      value: 'suspension_suspension_fork',
      label: 'Suspension fork',
      specifications: [
        wheelSize({ collapsible: false, isFilterable: true }),
        hubStandard({ isFilterable: true }),
        suspensionType({ isFilterable: true }),
        frontTravel,
      ],
    },
    {
      key: 'suspension_rear',
      value: 'suspension_rear',
      label: 'Rear suspension',
      specifications: [suspensionType({ collapsible: false }), suspensionMounting, suspensionLength, suspensionStroke],
    },
  ],
};

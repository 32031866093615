export const discipline = {
  id: 'discipline',
  key: 'discipline',
  label: 'Discipline',
  mandatory: false,
  isOpen: false,
  isFilterable: true,
  inputType: 'DROPDOWN',
  queryParamNames: ['pub_discipline'],
  translationGroup: 'discipline',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'xc_marathon', value: 'xc_marathon', label: 'XC/ Marathon' },
      { key: 'trail_all_mountain', value: 'trail_all_mountain', label: 'Trail/ All- Mountain' },
      { key: 'enduro', value: 'enduro', label: 'Enduro' },
      { key: 'downhill', value: 'downhill', label: 'Downhill' },
    ],
  },
};

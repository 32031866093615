export const gender = {
  id: 'gender',
  key: 'gender',
  label: 'Gender',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_gender'],
  translationGroup: 'gender',
  translatableLabels: true,
  config: {
    options: [
      { key: "men", value: "men", label: "Men's" },
      { key: "women", value: "women", label: "Women's" },
      { key: "kids", value: "kids", label: "Kids" },
      { key: "unisex", value: "unisex", label: "Unisex" },
    ]
  },
}

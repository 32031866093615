export const skiBootsFlexRate = {
  id: 'skiBootsFlexRate',
  key: 'ski_boots_flex_rate',
  label: 'Ski boots flex rate',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_skiBootsFlexRate'],
  translationGroup: 'ski_boots_flex_rate',
  translatableLabels: true,
  config: {
    options: [
      { key: 'very_soft', value: 'very_soft', label: 'Very soft (Less than 70)' },
      { key: 'soft', value: 'soft', label: 'Soft (70-90)' },
      { key: 'medium', value: 'medium', label: 'Medium (95-115)' },
      { key: 'stiff', value: 'stiff', label: 'Stiff (120-130)' },
      { key: 'very_stiff', value: 'very_stiff', label: 'Very stiff (More than 130)' },
    ],
  },
}

export const skiBootsType = {
  id: 'skiBootsType',
  key: 'ski_boots_type',
  label: 'Ski boots type',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_skiBootsType'],
  translationGroup: 'ski_boots_type',
  translatableLabels: true,
  config: {
    options: [
      {key: 'alpine', value: 'alpine', label: 'Alpine ski'},
      {key: 'alpine_touring_backcountry', value: 'alpine_touring_backcountry', label: 'Alpine Touring / Backcountry'},
      {key: 'cross_country', value: 'cross_country', label: 'Cross Country Skiing'},
      {key: 'park_pipe', value: 'park_pipe', label: 'Park pipe'},
    ]
  },
}

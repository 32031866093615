import { gears } from './gears';

export const placement = {
  id: 'placement',
  key: 'placement',
  label: 'Placement',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'placement',
  queryParamNames: ['pub_placement'],
  inputType: 'MULTISELECT',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      {
        key: 'front',
        value: 'front',
        label: 'Front',
        options: [gears({ mandatory: false, type: 'front' })],
      },
      {
        key: 'rear',
        value: 'rear',
        label: 'Rear',
        options: [gears({ mandatory: false, type: 'rear' })],
      },
      {
        key: 'set',
        value: 'set',
        label: 'Set',
        options: [gears({ mandatory: false, type: 'front' }), gears({ mandatory: false, type: 'rear' })],
      },
    ],
  },
};

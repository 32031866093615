export const snowboardBootsFlexRate = {
  id: 'snowboardBootsFlexRate',
  key: 'snowboard_boots_flex_rate',
  label: 'Snowboard boots flex rate',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_snowboardBootsFlexRate'],
  translationGroup: 'snowboard_boots_flex_rate',
  translatableLabels: true,
  config: {
    options: [
      { key: 'soft', value: 'soft', label: 'Soft' },
      { key: 'medium', value: 'medium', label: 'Medium' },
      { key: 'adjustable', value: 'adjustable', label: 'Adjustable' },
    ],
  },
}

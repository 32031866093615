export const brakesActivation = {
  id: 'brakesActivation',
  key: 'brakes_activation',
  label: 'Brakes activation',
  mandatory: false,
  isFilterable: true,
  isOpen: false,
  translationGroup: 'brakes_activation',
  queryParamNames: ['pub_brakesActivation'],
  inputType: 'CHECKBOX',
  type: 'SelectMultipleFilter',
  config: {
    schemaType: 'enum',
    options: [
      { key: 'hydraulic', value: 'hydraulic', label: 'Hydraulic' },
      { key: 'mechanical', value: 'mechanical', label: 'Mechanical' },
    ],
  },
};

import SeatCategoryLogo from '../../assets/part_categories/seat.png';
import { dropperActivation, dropperDiameter, dropperTravel, frameMaterial, seatLength } from '../specifications';

export const seat = {
  key: 'seat',
  label: 'Seat',
  logo: SeatCategoryLogo,
  subcategories: [
    {
      key: 'seat_dropper_post',
      value: 'seat_dropper_post',
      label: 'Dropper post',
      specifications: [dropperTravel, dropperDiameter, dropperActivation],
    },
    {
      key: 'seat_post',
      value: 'seat_post',
      label: 'Seat post',
      specifications: [
        seatLength,
        dropperDiameter,
        frameMaterial({ mandatory: false, collapsible: false, isFilterable: true }),
      ],
    },
    {
      key: 'seat_saddle',
      value: 'seat_saddle',
      label: 'Seat saddle',
      specifications: [],
    },
    {
      key: 'seat_other',
      value: 'seat_other',
      label: 'Other',
      specifications: [],
    },
  ],
};

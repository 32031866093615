import SnowboardCategoryLogo from '../../assets/snow_categories/snowboard.png';
import {
  bootsSize,
  gender,
  snowboardBindingsSize,
  snowboardBootsFlexRate,
  snowboardFlexRate,
  snowboardSize,
  snowboardType,
  withBindings
} from "./specifications";

export const snowboard = {
  key: 'snowboard',
  label: 'Snowboard',
  logo: SnowboardCategoryLogo,
  subcategories: [
    {
      key: 'snowboard',
      value: 'snowboard',
      label: 'Snowboard',
      specifications: [snowboardSize, snowboardType, snowboardFlexRate, withBindings, gender],
    },
    {
      key: 'snowboard_boots',
      value: 'snowboard_boots',
      label: 'Boots',
      specifications: [bootsSize, snowboardBootsFlexRate],
    },
    {
      key: 'snowboard_bindings',
      value: 'snowboard_bindings',
      label: 'Bindings',
      specifications: [snowboardBindingsSize],
    },
  ],
}
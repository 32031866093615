import SkisCategoryLogo from '../../assets/snow_categories/skis.png';
import {
  bootsSize,
  gender,
  polesSize,
  skiBindingsSize,
  skiBootsFlexRate,
  skiBootsType,
  skiLenght,
  skiWaistWidth,
  withBindings
} from "./specifications";

export const skis = {
  key: 'skis',
  label: 'Skis',
  logo: SkisCategoryLogo,
  subcategories: [
    {
      key: 'skis_alpine',
      value: 'skis_alpine',
      label: 'Alpine skis',
      specifications: [skiLenght, skiWaistWidth, withBindings, gender],
    },
    {
      key: 'skis_touring_backcountry',
      value: 'skis_touring_backcountry',
      label: 'Alpine touring / Backcountry',
      specifications: [skiLenght, skiWaistWidth, withBindings, gender],
    },
    {
      key: 'skis_cross_country',
      value: 'skis_cross_country',
      label: 'Cross country skis',
      specifications: [skiLenght, skiWaistWidth, withBindings, gender],
    },
    {
      key: 'skis_park_pipe',
      value: 'skis_park_pipe',
      label: 'Park pipe',
      specifications: [skiLenght, skiWaistWidth, withBindings, gender],
    },
    {
      key: 'skis_boots',
      value: 'skis_boots',
      label: 'Boots',
      specifications: [bootsSize, skiBootsType, skiBootsFlexRate],
    },
    {
      key: 'skis_bindings',
      value: 'skis_bindings',
      label: 'Bindings',
      specifications: [skiBindingsSize],
    },
    {
      key: 'skis_poles',
      value: 'skis_poles',
      label: 'Poles',
      specifications: [polesSize],
    },
  ],
}
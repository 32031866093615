export const stemLength = {
  id: 'stemLength',
  key: 'stem_length',
  label: 'Stem length',
  mandatory: false,
  collapsible: false,
  inputType: 'TEXT',
  type: 'SelectMultipleFilter',
  textInputType: 'number',
  maxLength: 5,
  abbreviation: 'mm',
  minValue: 30,
  maxValue: 180,
};

export const skiWaistWidth = {
  id: 'skiWaistWidth',
  key: 'ski_waist_width',
  label: 'Waist width',
  mandatory: false,
  collapsible: false,
  isFilterable: true,
  isOpen: false,
  inputType: 'DROPDOWN',
  type: 'DropDownFilter',
  queryParamNames: ['pub_skiWaistWidth'],
  translationGroup: 'ski_waist_width',
  skipItemPageTranslations: true,
  config: {
    options: [
      { key: "less_60", value: "less_60", label: "< 60 mm" },
      { key: "60_79", value: "60_79", label: "60-79 mm" },
      { key: "80_89", value: "80_89", label: "80-89 mm" },
      { key: "90_99", value: "90_99", label: "90-99 mm" },
      { key: "100_109", value: "100_109", label: "100-109 mm" },
      { key: "110_120", value: "110_120", label: "110-120 mm" },
      { key: "more_120", value: "more_120", label: "> 120 mm" }
    ]
    
  },
}
